const data = {
  across: {
    1: {
      clue: 'This 16th century Roman painter’s works often portrayed death and torture. He is known to have popularized the Baroque style of painting.',
      answer: 'CARAVAGGIO',
      row: 3,
      col: 0,
    },
    2: {
      clue: 'This American photographer is known for her emotionally charged and psychologically intense photographs, often of people on the fringes of society. (Last Name)',
      answer: 'ARBUS',
      row: 0,
      col: 8,
    },
    3: {
      clue: 'This Indian artist is known for his unique figurative style and incisive observations of class and sexuality. (First Name)',
      answer: 'BHUPEN',
      row: 5,
      col: 2,
    },
    4: {
      clue: 'This American Harlem Renaissance painter, known for his lyrically expressive style, used to do custodial work at the Whitney to support himself. (First Name)',
      answer: 'BEAUFORD',
      row: 7,
      col: 7,
    },
    5: {
      clue: 'This German photographer became the first non-British artist to have won the Turner Prize. (Last Name)',
      answer: 'TILLMANS',
      row: 8,
      col: 2,
    },
    6: {
      clue: 'This iconic American pop artist, managed the experimental rock band – The Velvet Underground. (Last Name)',
      answer: 'WARHOL',
      row: 10,
      col: 3,
    }
  },
  down: {
    1: {
      clue: 'After the sale of one of his works, this English pop artist became the most highly valued  living artist in the year 2018. (Last Name)',
      answer: 'HOCKNEY',
      row: 1,
      col: 0,
    },
    2: {
      clue: 'Known for capturing the last image of John Lennon, this American portrait photographer initially wanted to become an art teacher. (First Name)',
      answer: 'ANNIE',
      row: 0,
      col: 8,
    },
    3: {
      clue: 'Having emerged during the peak of the American Abstract Expressionism movement, this artist is best known for his series of works that inserted everyday objects into paintings, and ushered in a new wave of painting and sculpture. (First Name)',
      answer: 'ROBERT',
      row: 3,
      col: 2,
    },
    4: {
      clue: 'This figurative painter, known for his distinctively disturbing realism, was a close friend of the painter Lucien Freud. (Last Name)',
      answer: 'BACON',
      row: 7,
      col: 7,
    },
    5: {
      clue: 'Part of the New York based art collective, Group Material, this Cuban born artist was known for his minimalist installations and sculptures made using everyday materials. (First Name)',
      answer: 'FELIX',
      row: 7,
      col: 11,
    }
  },
};

export default data;
